<template>
  <template v-if="$store.getters._isAuthenticated">
    <AppLoader v-if="loaderEnabled" :logo="loaderLogo" />
    <div class="page d-flex flex-row flex-column-fluid">
      <Aside
        v-if="this.$store.getters._isSideBarActive"
        :lightLogo="themeLightLogo"
        :darkLogo="themeDarkLogo"
      />
      <div id="sx_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <Header :title="pageTitle" />
        <div id="sx_content" class="d-flex flex-column flex-column-fluid">
          <CustomPageToolbar
            v-if="$route.meta.isCustomPage"
            :breadcrumbs="breadcrumbs"
            :title="pageTitle"
          />
          <SetToolbar
            v-else-if="$route.meta.isSetPage"
            :breadcrumbs="breadcrumbs"
            :title="pageTitle"
          />
          <Toolbar v-else :breadcrumbs="breadcrumbs" :title="pageTitle" />
          <div class="d-flex flex-column-fluid sx-post">
            <template v-if="this.$route.meta.isDetailPage">
              <div class="w-100">
                <router-view />
              </div>
            </template>
            <template
              v-else-if="
                !this.$route.meta.isNewPage &&
                !this.$route.meta.isEditPage &&
                !this.$route.meta.isDetailPage &&
                !this.$route.meta.isDashboardPage
              "
            >
              <div class="container-fluid">
                <router-view />
              </div>
            </template>
            <template v-else>
              <router-view />
            </template>
          </div>
        </div>
      </div>
    </div>
    <ScrollTop v-if="$isMobile()" />
  </template>
</template>
<script>
import { computed, onMounted, watch, nextTick } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Aside from "@/layout/aside/Aside.vue";
import Header from "@/layout/header/Header.vue";
import HtmlClass from "@/core/services/LayoutService";
import Toolbar from "@/layout/toolbar/Toolbar.vue";
import CustomPageToolbar from "@/layout/toolbar/CustomPageToolbar.vue";
import SetToolbar from "@/layout/toolbar/SetToolbar.vue";
import ScrollTop from "@/layout/extras/ScrollTop.vue";
import { Actions } from "@/store/enums/StoreEnums";
import {
  MenuComponent,
  DrawerComponent,
  ScrollComponent,
} from "@/assets/ts/components";
import { removeModals, removeModalBackdrops } from "@/core/helpers/dom";
import { reinitializeComponents } from "@/core/plugins/themes";
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
} from "@/core/helpers/config";

export default {
  name: "Layout",
  components: {
    Aside,
    Header,
    Toolbar,
    ScrollTop,
    CustomPageToolbar,
    SetToolbar,
  },
  data() {
    return {};
  },
  methods: {
    getCurrentUserInStore() {
      var currentUser = this.$store.getters.userInfo;
      if (currentUser) {
        var sideBarActive = currentUser.activeMenu.isSideBarActive;
        if (!sideBarActive) {
          this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "aside-enabled");
          this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "aside-fixed");
          this.$store.dispatch(Actions.ADD_BODY_CLASSNAME, "aside-none");
        } else {
          this.$store.dispatch(Actions.ADD_BODY_CLASSNAME, "aside-enabled");
          this.$store.dispatch(Actions.ADD_BODY_CLASSNAME, "aside-fixed");
          this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "aside-none");
        }

        this.$root.setWindowUserDataFromState();
        reinitializeComponents();

        setTimeout(() => {
          this.$root.setWindowCompanyDataFromState();
        }, 100);
        setTimeout(() => {
          this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
        }, 1000);
      }
    },
  },
  watch: {
    "$store.getters.userInfo"() {
      this.getCurrentUserInStore();
    },
  },
  created() {
    DrawerComponent.bootstrap();
    ScrollComponent.bootstrap();
    DrawerComponent.updateAll();
    ScrollComponent.updateAll();
    this.getCurrentUserInStore();
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    // is aside minimize
    var sxAsideMinimize = Object.readCookie("SxAsideMinimize");
    if (
      !String.isNullOrWhiteSpace(sxAsideMinimize) &&
      sxAsideMinimize == "on"
    ) {
      store.dispatch(Actions.ADD_BODY_ATTRIBUTE, {
        qulifiedName: "data-sx-aside-minimize",
        value: "on",
      });
    }

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    onMounted(() => {
      // DrawerComponent.bootstrap();
      // ScrollComponent.bootstrap();
      // DrawerComponent.updateAll();
      // ScrollComponent.updateAll();

      nextTick(() => {
        reinitializeComponents();
      });
    });

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);
        DrawerComponent.hideAll();

        nextTick(() => {
          reinitializeComponents();
        });

        removeModals();
        removeModalBackdrops();
      }
    );

    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
    };
  },
};
</script>
