<template>
  <div
    class="toolbar well border-bottom"
    id="sx_toolbar"
    :class="{ 'mt-0': $isMobile() }"
  >
    <div
      id="sx_toolbar_container"
      :class="{
        'container-fluid': toolbarWidthFluid,
        'container-xxl': !toolbarWidthFluid,
      }"
      class="d-flex justify-content-between flex-stack"
    >
      <div
        data-sx-swapper="true"
        data-sx-swapper-mode="prepend"
        data-sx-swapper-parent="{default: '#sx_content_container', 'lg': '#sx_toolbar_container'}"
        class="page-title d-flex align-items-center flex-wrap ms-2 mb-lg-0"
        :class="{ 'mb-1': $isMobile() }"
      >
        <template
          v-if="
            this.breadcrumbs.length > 0
              ? this.breadcrumbs[0].name === 'HELP'
                ? true
                : false
              : false
          "
        >
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </template>
        <template v-else>
          <h1
            class="d-flex justify-content-center align-items-center text-dark fw-bolder my-1 fs-4 d-title"
          >
            {{ title }}
          </h1>
          <span
            v-if="breadcrumbs && !$isMobile()"
            class="h-20px border-gray-200 border-start mx-4 border-left-dashed"
          ></span>
          <ul
            v-if="breadcrumbs && !$isMobile()"
            class="breadcrumb breadcrumb-separatorless shadow-none fw-bold fs-7 my-1 ps-0"
          >
            <li class="breadcrumb-item pe-3 text-muted text-capitalize">
              <router-link :to="$appHomeUrl">
                {{
                  $t("Dashboard", {}, { locale: this.$store.state.activeLang })
                }}
              </router-link>
            </li>
            <template v-for="(item, index) in breadcrumbs" :key="index">
              <li class="breadcrumb-item">
                <span class="bullet w-5px h-2px"></span>
              </li>
              <li class="breadcrumb-item text-muted" v-if="item.isRouteLink">
                <router-link :to="item.html">
                  {{ item.name }}
                </router-link>
              </li>
              <li class="breadcrumb-item text-muted" v-else-if="!item.isHtml">
                {{ item.name }}
              </li>
              <li class="breadcrumb-item text-muted" v-else>
                <span v-html="item.html"></span>
              </li>
            </template>
          </ul>
        </template>
      </div>
      <div
        class="form-group"
        :class="{
          'text-center mt-2': $isMobile(),
        }"
        v-if="
          this.breadcrumbs.length > 0
            ? this.breadcrumbs[0].name === 'HELP'
              ? false
              : true
            : true
        "
      >
        <Dropdown
          v-if="customPageDto && customPageDto.isSwitchCustomPagesEnabled"
          routeName="customPage"
          requestUrl="rws-CustomPageController-UserPermittedDashboardList"
          :buttonName="title"
          buttonIcon="bi-app-indicator bi"
          buttonClasses="btn-primary"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { toolbarWidthFluid } from "@/core/helpers/config";
export default {
  name: "DashboardToolbar",
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
  },
  components: {},
  data() {
    return {
      customPageDto: this.$store.state.customPageDto,
    };
  },
  watch: {
    "$store.state.customPageDto"() {
      this.customPageDto = this.$store.state.customPageDto;
    },
  },
  setup() {
    return {
      toolbarWidthFluid,
    };
  },
};
</script>
